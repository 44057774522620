<template>
  <div>
    <v-snackbar color="red" v-model="showSnackbar" :timeout="5000">
      {{ snackbarText }}
      <v-btn color="#673ab7" text @click="showSnackbar = false"> Close </v-btn>
    </v-snackbar>
    <p class="left mt-3 label">{{ label }}</p>
    <div
      class="drop display-inline align-center"
      @dragover.prevent
      @drop="onDrop"
    >
      <input
        style="display: block"
        ref="fileInput"
        type="file"
        accept=".csv"
        name="csv"
        @change="onChange"
      />
      <v-btn class="mb-5 mt-5" v-if="!file" text @click="_onSelectButtonClick">
        SELECIONE OU ARRASTE O ARQUIVO CSV
      </v-btn>

      <v-container v-else class="mb-5 mt-5">
        <v-layout row class="justify-center">
          <v-progress-circular
            v-if="!imageUrl"
            :width="3"
            color="red"
            indeterminate
          ></v-progress-circular>
          <v-img
            v-else
            max-height="400px"
            max-width="600px"
            :src="imageUrl"
            alt=""
            class="img"
          />
        </v-layout>

        <v-layout row class="mt-5 justify-center">
          <v-btn text @click="removeFile"> REMOVER </v-btn>
        </v-layout>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: 'drop-file',
  props: {
    label: { type: String },
    imageUrl: { type: String },
    onFileRemoved: { type: Function },
    onFileSelected: { type: Function }
  },
  computed: {},
  data() {
    return {
      file: null,
      snackbarText: '',
      showSnackbar: false
    };
  },
  methods: {
    confirm() {
      this.confirmationAction();
    },
    onDrop: function (e) {
      e.stopPropagation();
      e.preventDefault();
      const files = e.dataTransfer.files;
      this.createFile(files[0]);
    },
    _onSelectButtonClick() {
      this.$refs.fileInput.click();
    },
    onChange(e) {
      const files = e.target.files;
      this.createFile(files[0]);
    },
    createFile(file) {
      if (file) {
        const fileReader = new FileReader();
        fileReader.onloadend = this.onFileSelected;
        setTimeout(() => {
          fileReader.readAsText(file, 'ISO-8859-1');
        }, 200);
      }
    },
    removeFile() {
      this.image = '';
      this.$refs.fileInput.value = '';
      this.onImageRemoved();
    }
  }
};
</script>

<style>
html,
body {
  height: 100%;
  text-align: center;
}

input[type='file'] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.align-center {
  text-align: center;
}

.label {
  color: #757575;
}

.display-inline {
  display: inline-block;
  vertical-align: middle;
}

.drop {
  background-color: #f2f2f2;
  border: 4px dashed #ccc;
  border-radius: 2px;
  height: 100%;
  width: 100%;
}
</style>
