<template>
  <v-container>
    <div v-if="showSelectColumns">
      <v-data-table
        :headers="complex.headers"
        :items="complex.items"
        class="elevation-0 fixed-table"
      >
        <template slot="items" slot-scope="props" class="justify-center">
          <td
            class="wide-cell"
            v-for="column in complex.headers"
            :key="column.text"
          >
            <v-select
              dense
              clearable
              :ref="column.value"
              class="smallFont"
              v-if="props.item['isSelect']"
              v-model="selectedMandatoryFields[column.value]"
              :items="mandatoryFields"
              item-text="text"
              item-value="key"
              @change="_onOnColumnSelect"
            ></v-select>
            <label class="smallFont" v-else>
              {{ props.item[column.value] }}</label
            >
          </td>
        </template>
      </v-data-table>
      <div class="text-xs-center pt-2">
        <v-btn
          :loading="loading"
          :disabled="!isAllMandatoryFieldsSelected"
          color="primary"
          @click="_saveColumns"
          >Salvar Colunas</v-btn
        >
      </div>
    </div>
    <v-container v-if="!editColumns">
      <v-layout row>
        <v-flex xs12 v-if="!data">
          <drop-file label="CSV file" :on-file-selected="_onFileSelected" />
        </v-flex>
        <v-flex xs12 v-else>
          <h3 class="mb-0">
            Deseja importar as {{ data.length }} peças contidas neste arquivo?
          </h3>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs12>
          <v-btn
            v-if="data"
            color="red"
            class="mt-5 white--text"
            :loading="loading"
            @click="_import"
          >
            Importar
          </v-btn>

          <v-btn
            v-if="data"
            color="secondary"
            class="mt-5 white--text"
            @click="
              () => {
                this.showSelectColumns = true;
                this.editColumns = true;
              }
            "
          >
            Editar Colunas
          </v-btn>
        </v-flex>
      </v-layout>
    </v-container>
    <v-alert v-model="error" transition="scale-transition" type="error">
      Algo deu errado, tente mais uma vez.
    </v-alert>
    <message-dialog
      :show.sync="showSelectColumnsMessage"
      :dismiss-action="
        () => {
          this.showSelectColumnsMessage = false;
        }
      "
      :message="selectColumnsMessage"
    >
    </message-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DropFile from '@/components/dialogs/DropFile';
import MessageDialog from '@/components/dialogs/MessageDialog';
import constants from '@/utils/constants';

export default {
  components: { DropFile, MessageDialog },
  data: () => ({
    currentCompany: null,
    data: null,
    csvContent: null,
    loading: false,
    error: false,
    showSelectColumns: false,
    editColumns: false,
    showSelectColumnsMessage: false,
    selectColumnsMessage:
      'Para que o sistema funcione corretamente você precisa mapear os campos obrigatórios para as colunas do seu arquivo CSV. Após salvar, esse passo não será necessário na próxima vez.',
    mandatoryFields: [
      { key: 'name', text: 'Nome', disabled: false },
      { key: 'pieceId', text: 'ID da Peça', disabled: false },
      { key: 'amount', text: 'Quantidade', disabled: false },
      { key: 'width', text: 'Largura', disabled: false },
      { key: 'height', text: 'Altura', disabled: false },
      { key: 'user', text: 'Nome do Cliente', disabled: false },
      { key: 'ambient', text: 'Ambiente', disabled: false }
    ],
    selectedMandatoryFields: {},
    columns: null,
    defaultColumns:
      'amount;width;height;material;name;ambient;user;moduleId;pieceId;address;neighborhoodAndCity;phone',
    complex: {
      headers: [],
      items: []
    }
  }),

  computed: {
    ...mapGetters(['getUser']),
    availableMandatoryFields() {
      return this.mandatoryFields.map((field) => {
        field['disabled'] = Object.values(
          this.selectedMandatoryFields
        ).includes(field.key);
        return field;
      });
    },
    isAllMandatoryFieldsSelected() {
      return (
        this.availableMandatoryFields.filter((field) => field.disabled !== true)
          .length === 0
      );
    }
  },

  async mounted() {
    const user = this.getUser;
    if (user.company) {
      this.currentCompany = await this._getCompany(user.company);
      if (this.currentCompany && this.currentCompany.columns) {
        this.columns = this.currentCompany.columns;
        this.showSelectColumns = false;
      }
    } else if (user.role === constants.ROLE_SUPER_USER) {
      this.showSelectColumnsMessage = true;
      this.showSelectColumns = true;
      this.columns = this.defaultColumns;
    }
  },
  methods: {
    ...mapActions({
      _importPieces: 'importPieces',
      _getCompany: 'getCompany',
      _updateCompany: 'updateCompany'
    }),
    _onOnColumnSelect() {
      this.mandatoryFields = this.mandatoryFields.map((field) => {
        field['disabled'] = Object.values(
          this.selectedMandatoryFields
        ).includes(field.key);
        return field;
      });
    },
    _onFileSelected(e) {
      this.csvContent = e.target.result;
      this._populateTable();
      if (this.columns) {
        this.showSelectColumns = false;
        this.data = this.csvToJson(this.csvContent, this.columns);
        this._setSelectedColumns();
      } else {
        this.showSelectColumns = true;
        this.showSelectColumnsMessage = true;
      }
    },
    async _saveColumns() {
      this.loading = true;
      try {
        this.columns = this._getSelectedColumns().join(';');
        if (this.currentCompany) {
          await this._updateCompany({
            _id: this.currentCompany._id,
            columns: this.columns
          });
        }
        this.data = this.csvToJson(this.csvContent, this.columns);
      } catch (e) {
        console.log(e);
        this.error = true;
      }
      this.loading = false;
      this.editColumns = false;
      this.showSelectColumns = false;
    },
    async _import() {
      try {
        this.loading = true;
        await this._importPieces(this.data);
        await this.$router.push('/');
      } catch (e) {
        console.log(e);
        this.error = true;
      }
      this.loading = false;
    },
    _getSelectedColumns() {
      return this.complex.headers.map((column) =>
        this.selectedMandatoryFields[column.value]
          ? this.selectedMandatoryFields[column.value]
          : column.value
      );
    },
    _populateTable() {
      const lines = this.csvToAnonymousJson(this.csvContent);
      if (lines.length > 0) {
        const row = lines[0];
        for (const column in row) {
          this.complex.headers.push({
            text: column.replace('column_', 'Coluna '),
            value: column,
            sortable: false,
            width: '200px'
          });
        }
        lines.push({ isSelect: true, _id: 'select' });
        this.complex.items = lines;
      }
    },
    _setSelectedColumns() {
      this.selectedMandatoryFields = {};
      const savedColumns = this.columns.split(';');
      this.complex.headers.map((column, index) => {
        if (!savedColumns[index].startsWith('column_')) {
          this.selectedMandatoryFields[column.value] = savedColumns[index];
        }
      });

      this.$emit(
        'update:availableMandatoryFields',
        this.selectedMandatoryFields
      );
    },
    csvToJson(csv, headersLine) {
      const lines = csv.split('\r\n');
      const result = [];
      const headers = headersLine.split(';');
      for (let i = 0; i < lines.length; i++) {
        const obj = {};
        if (lines[i] !== '') {
          const currentLine = lines[i].split(';');
          for (let j = 0; j < headers.length; j++) {
            if (j === 4) {
              currentLine[j] = this.replaceChars(currentLine[j]);
            }
            obj[headers[j]] = currentLine[j];
          }
          result.push(obj);
        }
      }
      return result;
    },
    csvToAnonymousJson(csv) {
      const result = [];
      const lines = csv.split('\r\n');
      if (lines.length > 0) {
        const headers = lines[0]
          .split(';')
          .map((column, index) => `column_${index}`)
          .join(';');
        const json = this.csvToJson(csv, headers);
        return json.length < 3 ? json : json.slice(0, 2);
      }
      return result;
    },
    replaceChars(text) {
      const charRegex = /(@\w\w)/g;
      return text.replace(charRegex, '').trim();
    }
  }
};
</script>
<style scoped lang="css">
.smallFont {
  font-size: 12px;
}
.fixed-table {
  table-layout: fixed;
}
.wide-cell {
  text-align: left;
  min-width: 200px;
  width: 200px;
  max-width: 200px;
}
.normal-cell {
  text-align: center;
}
</style>
